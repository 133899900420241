// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";

// Core variables and mixins overrides
@import "../../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../core/variables/components-variables";

.rdt_Wrapper {
  header {
    display: block;
    padding-left: 0;
    padding-right: 0;
  }
  .dataTable-custom {
    .rdt_Table {
      .rdt_TableBody {
        .rdt_TableRow {
          min-height: 50px;
        }
      }
    }
  }
}
.rdt_Pagination {
  #pagination-first-page,
  #pagination-previous-page,
  #pagination-next-page,
  #pagination-last-page {
    background: $pagination-bg-color;
    border-radius: 50%;
    &[disabled] {
      svg {
        fill: rgba($body-color, 0.5);
      }
    }
    &:not(:last-of-type) {
      margin-right: 0.5rem;
    }
  }
}

@media only screen and (max-width: 768px), (max-device-width: 768px) {
  .rdt_Wrapper {

    .rdt_TableHead {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
    .dataTable-custom {
      overflow-x: hidden;
      .rdt_Table {
        .rdt_TableBody {
          .rdt_TableRow {
            /* Behave  like a "row" */
            border: none;
            border-bottom: 3px solid $border-color;
            position: relative;
            display: block;
          }

          .rdt_TableRow:before {
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 6px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
          }

          .rdt_TableRow::before {
            font-weight: bold;
          }

          .rdt_TableRow > div[id|="cell"] {
            display: block;
          }
        }
      }
    }
  }
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr,
  tfoot {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead > tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border: 3px solid $border-color;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }

}

@media screen and (min-width:769px) and (max-width:1120px) {
  .dataTable-custom{
    overflow-x: hidden !important;
  }
}
