// ================================================================================================
//  File Name: custom.scss
//  Description: Custom Style For Dashboard Design.
//  ----------------------------------------------------------------------------------------------

// ================================================================================================

// ================================================================================================
//  Arrow Css Icon
// ================================================================================================

$arrowSize: 20px;
$arrowSpeed: 3s;
$arrowPeakopacity: 0.7;
$primaryArrowColor: #fbdacc;

.cursor-default {
  cursor: default;
} 

.moz-class.AIR_QUALITY-node{
  width: -moz-available !important;
}
.qrcodeContainer canvas{
  width: 320px !important;
}

.radiobox-alarms{
    display: flex;
    justify-content: center;
}

.alarmbadge{
  padding-right: 0;
  padding-left: 0;
  top: -30px  !important;
  right: -15px  !important;
  width: 18px;
  height: 18px;
  position:  relative  !important;
}


.selectbox {

  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNSIgaGVpZ2h0PSIyNSIgZmlsbD0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2U9IiNiYmIiPjxwYXRoIGQ9Ik02IDlsNiA2IDYtNiIvPjwvc3ZnPg==) !important;
  background-repeat: no-repeat !important;
  background-position-x: 95% !important;
  background-position-y: 50% !important;
  
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  -ms-appearance: none !important;
  -o-appearance: none !important;
  appearance: none !important;
}
.selectbox::-ms-expand {
  display: none;
}
 

.react-slidedown {
  transition-duration: 1s !important;
  transition-timing-function: ease-in-out;
}

.mainchart-container {
  margin-top: -7px !important;
  display: "flex";
  //-webkit-animation: slide-down .4s ease-out;
  //-moz-animation: slide-down .4s ease-out;
  // animation: fadeIn 2s;
  // -webkit-animation: fadeIn 2s;
  // -moz-animation: fadeIn 2s;
  // -o-animation: fadeIn 2s;
  // -ms-animation: fadeIn 2s;
}

.MuiCollapse-entered {
  @extend .col-12;
  @extend .col-lg-12;
  @extend .col-md-12;
  @extend .col-sm-12;
}

.chartcontainer {
  padding: 0px !important;
  margin-top: -10px !important;
}

.footerCard {
  background-color: $primaryArrowColor !important;
  border: 0px !important;
  padding: 15px !important;
}

.arrowgif {
  position: relative;
  /**/

  top: 20%;
  left: 50%;
  /**/
  width: $arrowSize;
  height: $arrowSize;
  transform: translate(-50%, -50%);

  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-left: calc($arrowSize/3) solid rgba(0, 0, 0, $arrowPeakopacity);
    border-bottom: calc($arrowSize/3) solid rgba(0, 0, 0, $arrowPeakopacity);
    transform: translate(calc($arrowSize/3), calc($arrowSize * 4 / 3)) rotate(-45deg);
    animation: arrowgif $arrowSpeed linear infinite;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-left: calc($arrowSize/3) solid rgba(0, 0, 0, $arrowPeakopacity);
    border-bottom: calc($arrowSize/3) solid rgba(0, 0, 0, $arrowPeakopacity);
    transform: translate(calc($arrowSize * 2 / 3), 0px) rotate(-45deg);
    animation: arrowgif $arrowSpeed linear infinite calc($arrowSpeed/-2);
  }
}

@keyframes arrowgif {
  0% {
    border-left: calc($arrowSize/3) solid rgba(0, 0, 0, 0);
    border-bottom: calc($arrowSize/3) solid rgba(0, 0, 0, 0);
    transform: translate(calc($arrowSize/-6), calc($arrowSize * 4 / -6)) rotate(-45deg);
  }

  10%,
  90% {
    border-left: calc($arrowSize/3) solid rgba(0, 0, 0, 0);
    border-bottom: calc($arrowSize/3) solid rgba(0, 0, 0, 0);
  }

  50% {
    border-left: calc($arrowSize/3) solid rgba(0, 0, 0, $arrowPeakopacity);
    border-bottom: calc($arrowSize/3) solid rgba(0, 0, 0, $arrowPeakopacity);
    transform: translate(calc($arrowSize/-6), 0px) rotate(-45deg);
  }

  100% {
    border-left: calc($arrowSize/3) solid rgba(0, 0, 0, 0);
    border-bottom: calc($arrowSize/3) solid rgba(0, 0, 0, 0);
    transform: translate(calc($arrowSize/-6), calc($arrowSize * 4 / 6)) rotate(-45deg);
  }
}





// ================================================================================================
//  Table Component Style
// ================================================================================================


@media only screen and (max-width: 768px), (max-device-width: 768px) {
 
    
  .device-list-table .rdt_TableRow {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex; 
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column; 
    display: flex !important;
    flex-direction: column !important;
    border: 2px solid #e2e2e2 !important;
  }


  .device-list-table .rdt_TableRow  > div:first-child  {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2 !important; 
  }
}
 
.dataTable-custom .rdt_TableCell{
  padding-bottom:10px;
}
.device-list-table .rdt_TableRow  button{
    
  background-color: #f5f5f5 !important;
}

.notificationtable-text{
  position:absolute;
  padding-top:10px;
}

#html5qr-code-full-region > div > img , #html5qr-code-full-region__dashboard_section_csr > span > button {
  display: none !important;
}


.apexcharts-zoom-icon,
.apexcharts-menu-icon {
  display: none !important;
}

/*

.apexcharts-legend {
  
    width: 140px !important;
    left: 5px !important;
    top: 70px !important;

  .apexcharts-legend-series{
     margin:0 !important;
     width: 124px !important;
     height: 55px !important;
     .apexcharts-legend-marker {
 
  height: 45px !important;
  width: 95px !important;
  margin-bottom: 20px !important;
  border-radius: 10px !important;
     }

     .apexcharts-legend-text{
      top: -55px !important;
      left: 20px !important;
 
     }
  }

 }




*/

.node-icon {
  padding-top: 20px;
  text-align: center;
  border-radius: 1200px;
  width: 115px;
  min-width: 115px;
  height: 115px;
  min-height: 115px;
  background-color: $black;
  border: 10px solid $orange-200;
}

.nodelists {
  width: 94%;
  margin: 3%;
}

.node-iconHeader {

  width: 50px;
  height: 50px;  
  display: inline-block;
  border-radius: 50%;
  margin-left: 10px;
  border: 4px solid #ff6d2e;
  background-color: #22292f;
  min-width: 50px !important;
}

.node-iconHeader i {
  text-align: center;
  padding-left: 9px;
  display: flex;
  color: rgb(255, 255, 255);
}

.card-title .node-iconHeaderTitle {
  max-width: 75%;
}

.react-tel {
  z-index: 1000;
}

.react-tel input {
  width: 100% !important;
}

@media (max-width: 768px) {
  .node-icon-container {
    width: 50%;
  }

  .node-data-container {
    width: 50%;
  }

  .chartbtn {
    font-size: 10px;
    padding: 10px !important;
  }
}

// Core variables and mixins
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_mixins";
@import "bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "core/variables/variables";
@import "~bootstrap/scss/_variables";

// Overrides user variable
@import "core/variables/components-variables";

html[dir="rtl"] {

  // dashboard sales card
  .sales-card {
    .img-left {
      left: 0;
      right: auto;
    }

    .img-right {
      right: 0;
    }
  }

  // breadcrumb
  .breadcrum-right {
    .dropdown-toggle {
      svg {
        right: 0;
      }
    }
  }

  //breadcrumb-header
  .breadcrumb-wrapper {
    .breadcrumb {
      .breadcrumb-item {
        &:before {
          transform: rotate(180deg);
        }
      }
    }
  }

  // Dropdown
  .dropup-form {
    .dropdown-menu {
      min-width: 16rem;
    }
  }

  // main menu icon
  .main-menu {
    ul.navigation-main {
      .nav-item {
        &:not(.open) {
          svg.menu-toggle-icon {
            transform: rotate(180deg);
          }
        }

        &.open {
          svg.menu-toggle-icon {
            transform: rotate(90deg) !important;
          }
        }
      }
    }
  }

  // ag-grid
  .ag-rtl {
    direction: rtl;

    .ag-header-select-all {
      margin-right: 0 !important;
      margin-left: 24px !important;
    }

    .ag-cell-value {
      margin-left: 0 !important;
      margin-right: 24px !important;
    }
  }

  // Code
  .component-code {
    pre {
      text-align: left;
    }
  }

  // Recharts
  .recharts-layer {
    direction: ltr;
  }

  // context menu
  .react-contexify {
    z-index: 1031;
  }

  // Email
  .email-application {
    .content-right {
      .app-fixed-search {
        .sidebar-toggle {
          margin-left: 0;
        }
      }

      .email-detail-header,
      .app-action {
        .dropdown {
          .dropdown-menu {
            transform: translate3d(0px, 22px, 0px) !important;
          }
        }
      }
    }
  }

  // carousel
  .carousel {

    .carousel-control-prev,
    .carousel-control-next {

      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        transform: rotate(180deg);
      }
    }
  }

  // pagination
  .pagination,
  .vx-pagination {

    .prev-item,
    .previous,
    .next-item,
    .next {
      a svg {
        transform: rotate(180deg);
      }
    }

    li:first-child,
    li:last-child {
      a span {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  // swiper
  .swiper-container {

    .swiper-button-next,
    .swiper-button-prev {
      &:after {
        transform: rotate(180deg);
      }
    }
  }

  .swiper-container-cube {
    &.swiper-container-rtl {
      .swiper-slide {
        transform-origin: 100% 0 !important;
      }
    }
  }

  //slider
  .rc-slider-tooltip {
    right: 50%;
    width: 30px;
  }

  .rc-slider-vertical {
    .rc-slider-dot {
      right: -1px;
    }
  }

  // Horizontal
  .horizontal-layout {
    .horizontal-menu-wrapper {
      #main-menu-navigation {
        .nav-item {
          .has-sub {
            svg.has-sub-arrow {
              transform: rotate(180deg);
            }
          }
        }

        .dropdown-menu {
          right: 0 !important;
          left: auto !important;
          top: 100% !important;

          .dropdown-menu {
            left: auto !important;
            right: 100% !important;
            top: 0 !important;
          }
        }
      }
    }
  }

  //  menu items alignment
  .main-menu {
    .navigation>li ul li>a {
      display: flex;
      align-items: center;
    }
  }

  // Dropdown RTL Changes
  .header-navbar .navbar-container ul.nav li.dropdown {
    .dropdown-menu {
      margin-top: 0;

      &::before {
        top: 1px;
      }
    }
  }

  .header-navbar {

    .dropdown,
    .dropup {
      .dropdown-menu.dropdown-menu-right::before {
        right: auto;
        left: 0.5rem;
      }
    }
  }

  .dropdown,
  .dropup {
    .dropdown-menu {
      right: auto !important;
      top: 0 !important;
      left: auto !important;

      &.dropdown-menu-right {
        left: auto !important;
        right: 0 !important;

        &::before {
          right: 0.6rem;
          left: auto;
        }
      }
    }
  }

  // Input Group dropdown
  .input-group {
    .dropdown-menu.show {
      top: 0 !important;
      right: auto !important;
      left: 0px !important;
    }
  }

  // Context menu list css
  .context-menu-list {
    z-index: 1031 !important;
  }

  code[class*="language-"],
  pre[class*="language-"] {
    direction: ltr;
  }

  @media print {

    code[class*="language-"],
    pre[class*="language-"] {
      text-shadow: none;
    }
  }

  // Calendar
  .app-calendar {
    .calendar-navigation {
      .btn {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  // Tooltip
  .bs-tooltip-left .arrow::before,
  .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: $tooltip-bg;
  }

  .bs-tooltip-left .arrow,
  .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    left: auto;
  }

  .bs-tooltip-right .arrow::before,
  .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: $tooltip-bg;
  }

  .bs-tooltip-right .arrow,
  .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    right: auto;
  }

  // Popover Style
  .popover {
    right: auto !important;
  }

  // Perfect scrollbar RTL fix
  body .ps__rail-y {
    right: auto !important;
    left: 1px !important;
  }
}

@media (max-width: 1200px) {
  html[dir="rtl"] {
    .vertical-layout {
      &.menu-collapsed {
        .app-content {
          margin-right: 0;
        }
      }
    }
  }
}

@media (max-width: 575.98px) {
  html[dir="rtl"] {
    .vertical-layout {
      .navbar-container {
        .header-navbar {
          ul.nav {
            .nav-item {
              &.dropdown {
                .dropdown-menu {
                  left: 12px !important;

                  &.dropdown-menu-media {
                    left: 15px !important;
                    right: -15px !important;
                  }
                }

                &.dropdown-notification {
                  .dropdown-menu {
                    transform: translate3d(-12px, 62px, 0px) !important;

                    &.dropdown-cart {
                      transform: translate3d(-12px, 59px, 0px) !important;
                    }
                  }
                }

                &.dropdown-user {
                  .dropdown-menu {
                    transform: translate3d(20px, 60px, 0px) !important;
                  }
                }

                &.dropdown-language {
                  .dropdown-menu {
                    right: 28px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 769px) {
  html[dir="rtl"] {
    .header-navbar {
      .dropdown {
        &.dropdown-notification {
          .dropdown-menu {
            transform: translate3d(324px, 62px, 0px) !important;

            &.dropdowncart {
              transform: translate3d(335px, 62px, 0px) !important;
            }
          }
        }

        &.dropdown-user {
          .dropdown-menu {
            transform: translate3d(20px, 60px, 0px) !important;
          }
        }

        &.dropdown-language {
          .dropdown-menu {
            transform: translate3d(114px, 64px, 0px) !important;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  html[dir="rtl"] {
    .app-content {
      .header-navbar {
        .dropdown {
          &.dropdown-notification {
            .dropdown-menu {
              transform: translate3d(26px, 59px, 0px) !important;

              &.dropdown-cart {
                transform: translate3d(26px, 59px, 0px) !important;
              }
            }
          }

          &.dropdown-user {
            .dropdown-menu {
              left: 0 !important;
              right: 28px !important;
            }
          }

          &.dropdown-language {
            .dropdown-menu {
              transform: translate3d(26px, 64px, 0px) !important;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 639px) and (-webkit-min-device-pixel-ratio: 1.5) {
  .flex-wrap-no-wrap {
    flex-wrap: nowrap;
  }
}
.flex-wrap-no-wrap {
  flex-wrap: nowrap;
}